<template>
	<div class="flex gap-1.5 justify-end">
		<template
			v-for="(icon, index) in icons"
			:key="index">
			<button
				data-id="product-icon-interaction"
				:aria-pressed="activeStates[index]"
				class="pt-1.5"
				@click="toggleActive(index, $event)">
				<img
					class="rounded-full size-[26px] p-1"
					:class="{ 'bg-dark': activeStates[index], 'bg-light': !activeStates[index] }"
					:src="icon"
					alt=""
					@click="toggleActive(index, $event)">
			</button>
		</template>
	</div>
</template>

<script setup>
import { useUsers } from "@/composables/useUsers";

const { userProfile, toggleLikedProduct, toggleLikedCompany, togglePurchasedProduct } = useUsers();

const props = defineProps({
	iconTypes: {
		type: Array,
		default: [],
	},
	icons: {
		type: Array,
		required: true,
	},
	id: {
		type: String,
		required: true,
	},
	selectedOption: {
		type: String,
		default: "products",
	},
});

const activeStates = reactive([]);

const initializeState = () => {
	activeStates.splice(0, activeStates.length);
	props.iconTypes?.forEach((icon) => {
		if (icon === "like") {
			activeStates.push(
				props.selectedOption === "products"
					? userProfile.value.likedProducts.includes(props.id)
					: userProfile.value.likedCompanies.includes(props.id),
			);
		} else if (icon === "cart") {
			activeStates.push(userProfile.value.purchasedProducts.includes(props.id));
		} else {
			activeStates.push(false);
		}
	});
};

const toggleActive = (index, event) => {
	event.stopPropagation();
	event.preventDefault();
	activeStates[index] = !activeStates[index];

	const iconType = props.iconTypes?.[index];

	if (iconType === "like") {
		if (props.selectedOption === "products") {
			toggleLikedProduct(props.id);
		} else if (props.selectedOption === "companies") {
			toggleLikedCompany(props.id);
		}
	}
	if (iconType === "cart") {
		togglePurchasedProduct(props.id);
	}
};

onMounted(initializeState);
watch(() => props.id, initializeState);
watch(() => props.selectedOption, initializeState);
</script>

<style scoped></style>
